button {
  padding: 10px 25px;
  border: 1px solid #eee;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  font-family: monospace;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 2px; }

.btnWhite {
  background-color: #eee;
  color: #141215; }
  .btnWhite a {
    color: #141215;
    transition: all 0.5s !important; }

.btnTransparent {
  background: transparent;
  pointer-events: none;
  color: #eee; }

.btnDark {
  background-color: #141215;
  color: #eee; }

.animation0 {
  animation-fill-mode: backwards !important; }

.skewedBtn {
  border-radius: 2px;
  transform: skew(-21deg);
  opacity: 0;
  transition: .3s all ease-in-out; }
  .skewedBtn > a, .skewedBtn span {
    transform: skew(21deg);
    display: inline-block; }

.btnFillLeft {
  cursor: none;
  overflow: hidden; }
  .btnFillLeft .fill {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    transition: all 0.3s !important; }
  .btnFillLeft:hover .fill {
    transform: translateX(0); }
  .btnFillLeft:hover a, .btnFillLeft:hover span {
    color: #141215 !important; }

.textCapitalize {
  text-transform: capitalize; }

.opacity0 {
  opacity: 0 !important;
  pointer-events: none; }

.opacity1 {
  opacity: 1 !important;
  pointer-events: unset; }

.z1 {
  z-index: 1 !important; }

.z0 {
  z-index: 0 !important; }

.z-1 {
  z-index: -1 !important; }

.dNone {
  display: none !important; }

.dBlock {
  display: block !important; }

.dFlex {
  display: flex !important; }

.bold {
  font-weight: 600 !important;
  color: #fff !important; }

.hC {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.vC {
  display: flex;
  align-items: center;
  flex-direction: column; }

.vHC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.mX1 {
  margin: 0 1rem; }

.mX2 {
  margin: 0 2rem; }

.mX3 {
  margin: 0 3rem; }

.mY1 {
  margin: 1rem 0; }

.mY2 {
  margin: 2rem 0; }

.mY3 {
  margin: 3rem 0; }

.mL1 {
  margin-left: 1rem !important; }

.mL2 {
  margin-left: 2rem !important; }

.mL3 {
  margin-left: 3rem !important; }

.mR1 {
  margin-right: 1rem; }

.mR2 {
  margin-right: 2rem; }

.mR3 {
  margin-right: 3rem; }

.mT1 {
  margin-top: 1rem !important; }

.mT2 {
  margin-top: 2rem !important; }

.mT3 {
  margin-top: 3rem !important; }

.mB1 {
  margin-bottom: 1rem !important; }

.mB2 {
  margin-bottom: 2rem !important; }

.mB3 {
  margin-bottom: 3rem !important; }

.t100 {
  transform: translateY(-100%); }

.t0 {
  transform: translateY(0); }

.monospace {
  font-family: monospace !important; }

.skyer {
  font-family: Skyer !important; }

.slideContentRight {
  -webkit-animation: slideRight 0.5s linear forwards;
  animation: slideRight 0.5s linear forwards; }

.fadeIn {
  will-change: transform, opacity,filter;
  -webkit-animation: fadeIn 0.3s linear forwards;
  animation: fadeIn 0.3s linear forwards; }

.fadeOut {
  will-change: transform, opacity,filter;
  -webkit-animation: fadeOut 0.3s linear forwards;
  animation: fadeOut 0.3s linear forwards; }

.fadeInDown {
  will-change: transform, opacity,filter;
  filter: blur(0);
  -webkit-animation: fadeInDown 0.3s linear forwards;
  animation: fadeInDown 0.3s linear forwards; }

.fadeInUp {
  will-change: transform, opacity,filter;
  filter: blur(0);
  -webkit-animation: fadeInUp 0.3s linear forwards;
  animation: fadeInUp 0.3s linear forwards; }

.fadeOutDown {
  will-change: transform, opacity,filter;
  filter: blur(1px);
  -webkit-animation: fadeOutDown 0.3s linear forwards;
  animation: fadeOutDown 0.3s linear forwards; }

.fadeOutUp {
  will-change: transform, opacity,filter;
  filter: blur(1px);
  -webkit-animation: fadeOutUp 0.3s linear forwards;
  animation: fadeOutUp 0.3s linear forwards; }

/********* Animations ***********/
@keyframes loadingBar {
  0% {
    width: 0%; }
  10% {
    width: 10%; }
  20% {
    width: 20%; }
  30% {
    width: 30%; }
  40% {
    width: 40%; }
  50% {
    width: 50%; }
  60% {
    width: 60%; }
  70% {
    width: 70%; }
  80% {
    width: 80%; }
  90% {
    width: 90%; }
  100% {
    width: 100%; } }

@keyframes slideRight {
  0% {
    transform: translateX(0%); }
  25% {
    transform: translateX(25%); }
  50% {
    transform: translateX(50%); }
  75% {
    transform: translateX(75%); }
  100% {
    transform: translateX(100%); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0); } }

@keyframes ripple {
  0% {
    transform: scale(1); }
  75% {
    transform: scale(1.75);
    opacity: 1; }
  100% {
    transform: scale(2);
    opacity: 0; } }

@keyframes scaleToggle {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

@font-face {
  font-family: "devicon";
  src: url(../fonts/devicon.eot);
  src: url(../fonts/devicon.eot) format("embedded-opentype"), url(../fonts/devicon.woff) format("woff"), url(../fonts/devicon.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

[class^="devicon-"],
[class*=" devicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "devicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.devicon-nixos-plain-wordmark:before {
  content: "\e992"; }

.devicon-nixos-plain:before {
  content: "\e9b2"; }

.devicon-perl-plain:before {
  content: "\e9b3"; }

.devicon-tensorflow-line-wordmark:before {
  content: "\e9b4"; }

.devicon-tensorflow-line:before {
  content: "\e9b5"; }

.devicon-tensorflow-original-wordmark:before {
  content: "\e9b6"; }

.devicon-tensorflow-original:before {
  content: "\e9b7"; }

.devicon-zig-plain-wordmark:before {
  content: "\e9b8"; }

.devicon-zig-original:before {
  content: "\e9b9"; }

.devicon-raspberrypi-line-wordmark:before {
  content: "\e9ba"; }

.devicon-raspberrypi-line:before {
  content: "\e9bb"; }

.devicon-embeddedc-plain-wordmark:before {
  content: "\e9bc"; }

.devicon-embeddedc-plain:before {
  content: "\e9bd"; }

.devicon-labview-plain-wordmark:before {
  content: "\e9be"; }

.devicon-labview-plain:before {
  content: "\e9bf"; }

.devicon-modx-plain-wordmark:before {
  content: "\e9c0"; }

.devicon-modx-plain:before {
  content: "\e9c1"; }

.devicon-knockout-plain-wordmark:before {
  content: "\e9c2"; }

.devicon-rstudio-plain:before {
  content: "\e9c3"; }

.devicon-phalcon-plain:before {
  content: "\e9c4"; }

.devicon-minitab-plain:before {
  content: "\e9c5"; }

.devicon-bulma-plain:before {
  content: "\e9c6"; }

.devicon-spss-plain:before {
  content: "\e9c7"; }

.devicon-matlab-line:before {
  content: "\e9c8"; }

.devicon-matlab-plain:before {
  content: "\e9c9"; }

.devicon-karma-plain:before {
  content: "\e9ca"; }

.devicon-jest-plain:before {
  content: "\e9cb"; }

.devicon-circleci-plain-wordmark:before {
  content: "\e9cc"; }

.devicon-circleci-plain:before {
  content: "\e9cd"; }

.devicon-codecov-plain:before {
  content: "\e9ce"; }

.devicon-magento-line:before {
  content: "\e9cf"; }

.devicon-magento-original-wordmark:before {
  content: "\e9d0"; }

.devicon-magento-original:before {
  content: "\e9d1"; }

.devicon-shopware-original-wordmark:before {
  content: "\e9d2"; }

.devicon-shopware-original:before {
  content: "\e9d3"; }

.devicon-salesforce-plain:before {
  content: "\e9d4"; }

.devicon-vuestorefront-plain:before {
  content: "\e9d5"; }

.devicon-unix-original:before {
  content: "\e9d6"; }

.devicon-godot-plain-wordmark:before {
  content: "\e9d7"; }

.devicon-godot-plain:before {
  content: "\e9d8"; }

.devicon-vscode-plain-wordmark:before {
  content: "\e9d9"; }

.devicon-vscode-plain:before {
  content: "\e9da"; }

.devicon-julia-plain-wordmark:before {
  content: "\e9db"; }

.devicon-julia-plain:before {
  content: "\e9dc"; }

.devicon-crystal-original-wordmark:before {
  content: "\e9dd"; }

.devicon-crystal-original:before {
  content: "\e9de"; }

.devicon-tailwindcss-plain:before {
  content: "\e9df"; }

.devicon-tailwindcss-original-wordmark:before {
  content: "\e9e0"; }

.devicon-weblate-plain-wordmark:before {
  content: "\e999"; }

.devicon-weblate-plain:before {
  content: "\e99a"; }

.devicon-thealgorithms-plain-wordmark:before {
  content: "\e99b"; }

.devicon-thealgorithms-plain:before {
  content: "\e99c"; }

.devicon-spring-plain-wordmark:before {
  content: "\e99d"; }

.devicon-spring-plain:before {
  content: "\e99e"; }

.devicon-rails-plain-wordmark:before {
  content: "\e99f"; }

.devicon-rails-plain:before {
  content: "\e9a0"; }

.devicon-phoenix-plain-wordmark:before {
  content: "\e9a1"; }

.devicon-phoenix-plain:before {
  content: "\e9a2"; }

.devicon-nextjs-original-wordmark:before {
  content: "\e9a3"; }

.devicon-nextjs-line:before {
  content: "\e9a4"; }

.devicon-nextjs-original:before {
  content: "\e9a5"; }

.devicon-lua-plain-wordmark:before {
  content: "\e9a6"; }

.devicon-lua-plain:before {
  content: "\e9a7"; }

.devicon-graphql-plain-wordmark:before {
  content: "\e9a8"; }

.devicon-graphql-plain:before {
  content: "\e9a9"; }

.devicon-gitter-plain-wordmark:before {
  content: "\e9aa"; }

.devicon-gitter-plain:before {
  content: "\e9ab"; }

.devicon-figma-plain:before {
  content: "\e9ac"; }

.devicon-digitalocean-plain-wordmark:before {
  content: "\e9ad"; }

.devicon-digitalocean-plain:before {
  content: "\e9ae"; }

.devicon-dotnetcore-plain:before {
  content: "\e9af"; }

.devicon-dart-plain-wordmark:before {
  content: "\e9b0"; }

.devicon-dart-plain:before {
  content: "\e9b1"; }

.devicon-r-plain:before {
  content: "\e98e"; }

.devicon-r-original:before {
  content: "\e98f"; }

.devicon-ocaml-plain-wordmark:before {
  content: "\e990"; }

.devicon-ocaml-plain:before {
  content: "\e991"; }

.devicon-jupyter-plain-wordmark:before {
  content: "\e993"; }

.devicon-jupyter-plain:before {
  content: "\e994"; }

.devicon-fsharp-plain:before {
  content: "\e995"; }

.devicon-elixir-plain-wordmark:before {
  content: "\e996"; }

.devicon-elixir-plain:before {
  content: "\e997"; }

.devicon-aarch64-plain:before {
  content: "\e998"; }

.devicon-xd-line:before {
  content: "\e97a"; }

.devicon-xd-plain:before {
  content: "\e97b"; }

.devicon-uwsgi-plain:before {
  content: "\e97c"; }

.devicon-microsoftsqlserver-plain-wordmark:before {
  content: "\e97d"; }

.devicon-microsoftsqlserver-plain:before {
  content: "\e97e"; }

.devicon-sqlalchemy-original-wordmark:before {
  content: "\e97f"; }

.devicon-sqlalchemy-plain:before {
  content: "\e980"; }

.devicon-rocksdb-plain:before {
  content: "\e981"; }

.devicon-objectivec-plain:before {
  content: "\e982"; }

.devicon-kubernetes-plain-wordmark:before {
  content: "\e983"; }

.devicon-kubernetes-plain:before {
  content: "\e984"; }

.devicon-googlecloud-plain-wordmark:before {
  content: "\e985"; }

.devicon-googlecloud-plain:before {
  content: "\e986"; }

.devicon-flask-original-wordmark:before {
  content: "\e987"; }

.devicon-flask-original:before {
  content: "\e988"; }

.devicon-firebase-plain-wordmark:before {
  content: "\e989"; }

.devicon-firebase-plain:before {
  content: "\e98a"; }

.devicon-eleventy-plain:before {
  content: "\e98b"; }

.devicon-appwrite-plain-wordmark:before {
  content: "\e98c"; }

.devicon-appwrite-plain:before {
  content: "\e98d"; }

.devicon-bash-plain:before {
  content: "\e979"; }

.devicon-woocommerce-plain-wordmark:before {
  content: "\e966"; }

.devicon-woocommerce-plain:before {
  content: "\e967"; }

.devicon-typo3-plain-wordmark:before {
  content: "\e968"; }

.devicon-typo3-plain:before {
  content: "\e969"; }

.devicon-premierepro-plain:before {
  content: "\e96a"; }

.devicon-nestjs-plain-wordmark:before {
  content: "\e96b"; }

.devicon-nestjs-plain:before {
  content: "\e96c"; }

.devicon-materialui-plain:before {
  content: "\e96d"; }

.devicon-kotlin-plain-wordmark:before {
  content: "\e96e"; }

.devicon-kotlin-plain:before {
  content: "\e96f"; }

.devicon-jenkins-plain:before {
  content: "\e970"; }

.devicon-jenkins-line:before {
  content: "\e971"; }

.devicon-haxe-plain:before {
  content: "\e972"; }

.devicon-gatsby-plain-wordmark:before {
  content: "\e973"; }

.devicon-gatsby-plain:before {
  content: "\e974"; }

.devicon-flutter-plain:before {
  content: "\e975"; }

.devicon-composer-line-wordmark:before {
  content: "\e976"; }

.devicon-composer-line:before {
  content: "\e977"; }

.devicon-aftereffects-plain:before {
  content: "\e978"; }

.devicon-yunohost-plain:before {
  content: "\e963"; }

.devicon-redux-original:before {
  content: "\e964"; }

.devicon-clojurescript-plain:before {
  content: "\e965"; }

.devicon-clojure-line:before {
  content: "\e962"; }

.devicon-mocha-plain:before {
  content: "\e961"; }

.devicon-haskell-plain:before {
  content: "\e95f"; }

.devicon-haskell-plain-wordmark:before {
  content: "\e960"; }

.devicon-codepen-original-wordmark:before {
  content: "\e95d"; }

.devicon-codepen-plain:before {
  content: "\e95e"; }

.devicon-groovy-plain:before {
  content: "\e958"; }

.devicon-rust-plain:before {
  content: "\e959"; }

.devicon-scala-plain-wordmark:before {
  content: "\e95a"; }

.devicon-scala-plain:before {
  content: "\e95b"; }

.devicon-grails-plain:before {
  content: "\e95c"; }

.devicon-sketch-line-wordmark:before {
  content: "\e94c"; }

.devicon-sketch-line:before {
  content: "\e94d"; }

.devicon-npm-original-wordmark:before {
  content: "\e952"; }

.devicon-ionic-original-wordmark:before {
  content: "\e953"; }

.devicon-ionic-original:before {
  content: "\e954"; }

.devicon-ember-original-wordmark:before {
  content: "\e955"; }

.devicon-electron-original-wordmark:before {
  content: "\e956"; }

.devicon-electron-original:before {
  content: "\e957"; }

.devicon-vagrant-plain-wordmark:before {
  content: "\e94e"; }

.devicon-vagrant-plain:before {
  content: "\e94f"; }

.devicon-yarn-plain-wordmark:before {
  content: "\e950"; }

.devicon-yarn-plain:before {
  content: "\e951"; }

.devicon-handlebars-plain-wordmark:before {
  content: "\e94a"; }

.devicon-handlebars-plain:before {
  content: "\e94b"; }

.devicon-couchdb-plain-wordmark:before {
  content: "\e948"; }

.devicon-couchdb-plain:before {
  content: "\e949"; }

.devicon-behance-plain-wordmark:before {
  content: "\e943"; }

.devicon-behance-plain:before {
  content: "\e945"; }

.devicon-linkedin-plain-wordmark:before {
  content: "\e946"; }

.devicon-linkedin-plain:before {
  content: "\e947"; }

.devicon-ceylon-plain:before {
  content: "\e944"; }

.devicon-elm-plain-wordmark:before {
  content: "\e941"; }

.devicon-elm-plain:before {
  content: "\e942"; }

.devicon-cakephp-plain-wordmark:before {
  content: "\e93f"; }

.devicon-cakephp-plain:before {
  content: "\e940"; }

.devicon-stylus-original:before {
  content: "\e93e"; }

.devicon-express-original-wordmark:before {
  content: "\e93c"; }

.devicon-express-original:before {
  content: "\e93d"; }

.devicon-devicon-plain-wordmark:before {
  content: "\e93a"; }

.devicon-devicon-plain:before {
  content: "\e93b"; }

.devicon-intellij-plain-wordmark:before {
  content: "\e932"; }

.devicon-intellij-plain:before {
  content: "\e933"; }

.devicon-pycharm-plain-wordmark:before {
  content: "\e934"; }

.devicon-pycharm-plain:before {
  content: "\e935"; }

.devicon-rubymine-plain-wordmark:before {
  content: "\e936"; }

.devicon-rubymine-plain:before {
  content: "\e937"; }

.devicon-webstorm-plain-wordmark:before {
  content: "\e938"; }

.devicon-webstorm-plain:before {
  content: "\e939"; }

.devicon-tomcat-line-wordmark:before {
  content: "\e92c"; }

.devicon-tomcat-line:before {
  content: "\e92d"; }

.devicon-vuejs-line-wordmark:before {
  content: "\e92e"; }

.devicon-vuejs-line:before {
  content: "\e92f"; }

.devicon-vuejs-plain-wordmark:before {
  content: "\e930"; }

.devicon-vuejs-plain:before {
  content: "\e931"; }

.devicon-swift-plain-wordmark:before {
  content: "\e92a"; }

.devicon-swift-plain:before {
  content: "\e92b"; }

.devicon-webpack-plain-wordmark:before {
  content: "\e928"; }

.devicon-webpack-plain:before {
  content: "\e929"; }

.devicon-visualstudio-plain-wordmark:before {
  content: "\e926"; }

.devicon-visualstudio-plain:before {
  content: "\e927"; }

.devicon-slack-plain-wordmark:before {
  content: "\e924"; }

.devicon-slack-plain:before {
  content: "\e925"; }

.devicon-sequelize-plain-wordmark:before {
  content: "\e922"; }

.devicon-sequelize-plain:before {
  content: "\e923"; }

.devicon-typescript-plain:before {
  content: "\e920"; }

.devicon-babel-plain:before {
  content: "\e921"; }

.devicon-facebook-plain:before {
  content: "\e91c"; }

.devicon-google-plain-wordmark:before {
  content: "\e91d"; }

.devicon-google-plain:before {
  content: "\e91e"; }

.devicon-twitter-original:before {
  content: "\e91f"; }

.devicon-mocha:before {
  content: "\e919"; }

.devicon-jasmine-plain:before {
  content: "\e91a"; }

.devicon-jasmine-wordmark:before {
  content: "\e91b"; }

.devicon-gatling-plain:before {
  content: "\e917"; }

.devicon-gatling-plain-wordmark:before {
  content: "\e918"; }

.devicon-phpstorm-plain:before {
  content: "\e911"; }

.devicon-phpstorm-plain-wordmark:before {
  content: "\e912"; }

.devicon-sourcetree-original:before {
  content: "\e913"; }

.devicon-sourcetree-original-wordmark:before {
  content: "\e914"; }

.devicon-ssh-original:before {
  content: "\e915"; }

.devicon-ssh-original-wordmark:before {
  content: "\e916"; }

.devicon-jeet-plain:before {
  content: "\e903"; }

.devicon-jeet-plain-wordmark:before {
  content: "\e906"; }

.devicon-gitlab-plain:before {
  content: "\e907"; }

.devicon-gitlab-plain-wordmark:before {
  content: "\e908"; }

.devicon-github-original:before {
  content: "\e909"; }

.devicon-github-original-wordmark:before {
  content: "\e90a"; }

.devicon-d3js-plain:before {
  content: "\e90b"; }

.devicon-d3js-original:before {
  content: "\e90c"; }

.devicon-confluence-original:before {
  content: "\e90d"; }

.devicon-confluence-original-wordmark:before {
  content: "\e90e"; }

.devicon-bitbucket-original:before {
  content: "\e90f"; }

.devicon-bitbucket-original-wordmark:before {
  content: "\e910"; }

.devicon-gradle-plain:before {
  content: "\e902"; }

.devicon-gradle-plain-wordmark:before {
  content: "\e8f1"; }

.devicon-cucumber-plain:before {
  content: "\e904"; }

.devicon-cucumber-plain-wordmark:before {
  content: "\e905"; }

.devicon-protractor-plain:before {
  content: "\e900"; }

.devicon-protractor-plain-wordmark:before {
  content: "\e901"; }

.devicon-safari-line-wordmark:before {
  content: "\e632"; }

.devicon-safari-line:before {
  content: "\e63a"; }

.devicon-safari-plain-wordmark:before {
  content: "\e63b"; }

.devicon-safari-plain:before {
  content: "\e63c"; }

.devicon-jetbrains-plain:before {
  content: "\e63d"; }

.devicon-django-line:before {
  content: "\e63e"; }

.devicon-django-plain:before {
  content: "\e63f"; }

.devicon-gimp-plain:before {
  content: "\e633"; }

.devicon-redhat-plain-wordmark:before {
  content: "\e62a"; }

.devicon-redhat-plain:before {
  content: "\e62b"; }

.devicon-cplusplus-line:before {
  content: "\e634"; }

.devicon-cplusplus-plain:before {
  content: "\e635"; }

.devicon-csharp-line:before {
  content: "\e636"; }

.devicon-csharp-plain:before {
  content: "\e637"; }

.devicon-c-line:before {
  content: "\e638"; }

.devicon-c-plain:before {
  content: "\e639"; }

.devicon-nodewebkit-line-wordmark:before {
  content: "\e611"; }

.devicon-nodewebkit-line:before {
  content: "\e612"; }

.devicon-nodewebkit-plain-wordmark:before {
  content: "\e613"; }

.devicon-nodewebkit-plain:before {
  content: "\e614"; }

.devicon-nginx-original:before {
  content: "\e615"; }

.devicon-erlang-plain-wordmark:before {
  content: "\e616"; }

.devicon-erlang-plain:before {
  content: "\e617"; }

.devicon-doctrine-line-wordmark:before {
  content: "\e618"; }

.devicon-doctrine-line:before {
  content: "\e619"; }

.devicon-doctrine-plain-wordmark:before {
  content: "\e61a"; }

.devicon-doctrine-plain:before {
  content: "\e625"; }

.devicon-apache-line-wordmark:before {
  content: "\e626"; }

.devicon-apache-line:before {
  content: "\e627"; }

.devicon-apache-plain-wordmark:before {
  content: "\e628"; }

.devicon-apache-plain:before {
  content: "\e629"; }

.devicon-go-line:before {
  content: "\e610"; }

.devicon-redis-plain-wordmark:before {
  content: "\e606"; }

.devicon-redis-plain:before {
  content: "\e607"; }

.devicon-meteor-plain-wordmark:before {
  content: "\e608"; }

.devicon-meteor-plain:before {
  content: "\e609"; }

.devicon-heroku-line-wordmark:before {
  content: "\e60a"; }

.devicon-heroku-line:before {
  content: "\e60b"; }

.devicon-heroku-plain-wordmark:before {
  content: "\e60c"; }

.devicon-heroku-plain:before {
  content: "\e60f"; }

.devicon-go-plain:before {
  content: "\e61b"; }

.devicon-docker-plain-wordmark:before {
  content: "\e61e"; }

.devicon-docker-plain:before {
  content: "\e61f"; }

.devicon-symfony-original-wordmark:before {
  content: "\e602"; }

.devicon-symfony-original:before {
  content: "\e605"; }

.devicon-react-original-wordmark:before {
  content: "\e600"; }

.devicon-react-original:before {
  content: "\e601"; }

.devicon-amazonwebservices-original:before {
  content: "\e603"; }

.devicon-amazonwebservices-plain-wordmark:before {
  content: "\e604"; }

.devicon-android-plain-wordmark:before {
  content: "\e60d"; }

.devicon-android-plain:before {
  content: "\e60e"; }

.devicon-angularjs-plain-wordmark:before {
  content: "\e61c"; }

.devicon-angularjs-plain:before {
  content: "\e61d"; }

.devicon-appcelerator-original:before {
  content: "\e620"; }

.devicon-appcelerator-plain-wordmark:before {
  content: "\e621"; }

.devicon-apple-original:before {
  content: "\e622"; }

.devicon-atom-original-wordmark:before {
  content: "\e623"; }

.devicon-atom-original:before {
  content: "\e624"; }

.devicon-backbonejs-plain-wordmark:before {
  content: "\e62c"; }

.devicon-backbonejs-plain:before {
  content: "\e62d"; }

.devicon-bootstrap-plain-wordmark:before {
  content: "\e62e"; }

.devicon-bootstrap-plain:before {
  content: "\e62f"; }

.devicon-bower-line-wordmark:before {
  content: "\e630"; }

.devicon-bower-line:before {
  content: "\e631"; }

.devicon-bower-plain-wordmark:before {
  content: "\e64e"; }

.devicon-bower-plain:before {
  content: "\e64f"; }

.devicon-chrome-plain-wordmark:before {
  content: "\e665"; }

.devicon-chrome-plain:before {
  content: "\e666"; }

.devicon-codeigniter-plain-wordmark:before {
  content: "\e667"; }

.devicon-codeigniter-plain:before {
  content: "\e668"; }

.devicon-coffeescript-original-wordmark:before {
  content: "\e669"; }

.devicon-coffeescript-original:before {
  content: "\e66a"; }

.devicon-css3-plain-wordmark:before {
  content: "\e678"; }

.devicon-css3-plain:before {
  content: "\e679"; }

.devicon-debian-plain-wordmark:before {
  content: "\e67e"; }

.devicon-debian-plain:before {
  content: "\e67f"; }

.devicon-dot-net-plain-wordmark:before {
  content: "\e6d3"; }

.devicon-dot-net-plain:before {
  content: "\e6d4"; }

.devicon-drupal-plain-wordmark:before {
  content: "\e6e2"; }

.devicon-drupal-plain:before {
  content: "\e6e3"; }

.devicon-firefox-plain-wordmark:before {
  content: "\e75d"; }

.devicon-firefox-plain:before {
  content: "\e75e"; }

.devicon-foundation-plain-wordmark:before {
  content: "\e7a2"; }

.devicon-foundation-plain:before {
  content: "\e7a3"; }

.devicon-git-plain-wordmark:before {
  content: "\e7a7"; }

.devicon-git-plain:before {
  content: "\e7a8"; }

.devicon-grunt-line-wordmark:before {
  content: "\e7a9"; }

.devicon-grunt-line:before {
  content: "\e7aa"; }

.devicon-grunt-plain-wordmark:before {
  content: "\e7ea"; }

.devicon-grunt-plain:before {
  content: "\e7eb"; }

.devicon-gulp-plain:before {
  content: "\e7ec"; }

.devicon-html5-plain-wordmark:before {
  content: "\e7f6"; }

.devicon-html5-plain:before {
  content: "\e7f7"; }

.devicon-ie10-original:before {
  content: "\e7f8"; }

.devicon-illustrator-line:before {
  content: "\e7f9"; }

.devicon-illustrator-plain:before {
  content: "\e7fa"; }

.devicon-inkscape-plain-wordmark:before {
  content: "\e834"; }

.devicon-inkscape-plain:before {
  content: "\e835"; }

.devicon-java-plain-wordmark:before {
  content: "\e841"; }

.devicon-java-plain:before {
  content: "\e842"; }

.devicon-javascript-plain:before {
  content: "\e845"; }

.devicon-jquery-plain-wordmark:before {
  content: "\e849"; }

.devicon-jquery-plain:before {
  content: "\e84a"; }

.devicon-krakenjs-plain-wordmark:before {
  content: "\e84f"; }

.devicon-krakenjs-plain:before {
  content: "\e850"; }

.devicon-laravel-plain-wordmark:before {
  content: "\e851"; }

.devicon-laravel-plain:before {
  content: "\e852"; }

.devicon-less-plain-wordmark:before {
  content: "\e853"; }

.devicon-linux-plain:before {
  content: "\eb1c"; }

.devicon-mongodb-plain-wordmark:before {
  content: "\eb43"; }

.devicon-mongodb-plain:before {
  content: "\eb44"; }

.devicon-moodle-plain-wordmark:before {
  content: "\eb5a"; }

.devicon-moodle-plain:before {
  content: "\eb5b"; }

.devicon-mysql-plain-wordmark:before {
  content: "\eb60"; }

.devicon-mysql-plain:before {
  content: "\eb61"; }

.devicon-nodejs-plain-wordmark:before {
  content: "\eb69"; }

.devicon-nodejs-plain:before {
  content: "\eb6a"; }

.devicon-oracle-original:before {
  content: "\eb6b"; }

.devicon-photoshop-line:before {
  content: "\eb6c"; }

.devicon-photoshop-plain:before {
  content: "\eb6d"; }

.devicon-php-plain:before {
  content: "\eb71"; }

.devicon-postgresql-plain-wordmark:before {
  content: "\eb7c"; }

.devicon-postgresql-plain:before {
  content: "\eb7d"; }

.devicon-python-plain-wordmark:before {
  content: "\eb88"; }

.devicon-python-plain:before {
  content: "\eb89"; }

.devicon-ruby-plain-wordmark:before {
  content: "\ebc9"; }

.devicon-ruby-plain:before {
  content: "\ebca"; }

.devicon-sass-original:before {
  content: "\ebcb"; }

.devicon-travis-plain-wordmark:before {
  content: "\ebcc"; }

.devicon-travis-plain:before {
  content: "\ebcd"; }

.devicon-trello-plain-wordmark:before {
  content: "\ebce"; }

.devicon-trello-plain:before {
  content: "\ebcf"; }

.devicon-ubuntu-plain-wordmark:before {
  content: "\ebd0"; }

.devicon-ubuntu-plain:before {
  content: "\ebd1"; }

.devicon-vim-plain:before {
  content: "\ebf3"; }

.devicon-windows8-original-wordmark:before {
  content: "\ebf4"; }

.devicon-windows8-original:before {
  content: "\ebf5"; }

.devicon-wordpress-plain-wordmark:before {
  content: "\ebfd"; }

.devicon-wordpress-plain:before {
  content: "\ebfe"; }

.devicon-yii-plain-wordmark:before {
  content: "\ec01"; }

.devicon-yii-plain:before {
  content: "\ec02"; }

.devicon-zend-plain-wordmark:before {
  content: "\ec03"; }

.devicon-zend-plain:before {
  content: "\ec04"; }

.lodash-icon {
  content: url(../images/lodash.svg);
  width: 34px;
  height: 32px; }

.jest-icon {
  content: url(../images/jest.svg);
  width: 34px;
  height: 40px; }

.ionic-icon {
  content: url(../images/ionic.svg);
  width: 34px;
  height: 32px; }

.nightwatch-icon {
  content: url(../images/nightwatch.svg);
  width: 34px;
  height: 42px; }

.next-icon {
  content: url(../images/next.svg);
  width: 50px;
  height: 32px; }

.nuxt-icon {
  content: url(../images/nuxt.svg);
  width: 34px;
  height: 32px; }

.facebook-icon {
  content: url(../images/facebook.svg);
  width: 16px;
  height: 16px; }

.github-icon {
  content: url(../images/github.svg);
  width: 16px;
  height: 16px; }

.linkedin-icon {
  content: url(../images/linkedin.svg);
  width: 16px;
  height: 16px; }

.email-icon {
  content: url(../images/mail2.svg);
  width: 14px;
  height: 14px; }

.loader-icon {
  content: url(../images/ripple.svg);
  width: 75px;
  height: 75px; }

.back-icon {
  content: url(../images/back.svg);
  width: 22px;
  height: 20px; }

.external-icon {
  content: url(../images/external.svg);
  width: 13px;
  height: 13px; }

.line-icon {
  content: url(../images/line.svg); }

.medium-icon {
  content: url(../images/medium.png);
  width: 14px;
  height: 14px; }

.puppeteer-icon {
  content: url(../images/puppeteer-icon.png);
  width: 32px;
  height: 43px; }

.websocket-icon {
  content: url(../images/web-sockets.svg);
  width: 34px;
  height: 30px; }

@font-face {
  font-family: skyer;
  font-display: swap;
  src: url(../fonts/Skyer.otf); }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141215;
  color: #eee;
  overflow: hidden;
  cursor: none;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  padding: 2rem; }
  @media (max-width: 768px) {
    body {
      padding: 12px; } }
  body ::selection {
    background: #999; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5 {
    font-family: Skyer;
    margin: 0 0;
    letter-spacing: 1.5px;
    color: #eee; }
  body p,
  body span,
  body a,
  body li {
    font-family: monospace;
    color: #eee; }
  body a {
    text-decoration: none;
    cursor: none;
    letter-spacing: 2px; }
  body #root .pointer {
    cursor: none; }
  body #root .cursor {
    width: 30px;
    height: 30px;
    border: 2px solid #eee;
    z-index: 999;
    border-radius: 50%;
    position: absolute;
    transition: 0.15s all ease-out;
    pointer-events: none;
    will-change: transform, opacity;
    -webkit-font-smoothing: antialiased; }
    body #root .cursor::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: #eee;
      left: 13.7px;
      top: 13.5px; }
    body #root .cursor .rightArrow {
      content: url(../images/rightarrow.svg);
      width: 32px;
      height: 20px;
      position: absolute;
      top: 4px;
      left: 13px;
      opacity: 0;
      transition: 0.3s all ease-in-out; }
    body #root .cursor.showArrow {
      border-style: dashed; }
      body #root .cursor.showArrow .rightArrow {
        opacity: 1; }
      body #root .cursor.showArrow::after {
        opacity: 0; }
    body #root .cursor.expand {
      z-index: 999;
      background-color: #fff;
      animation: scaleToggle 0.3s forwards; }
  body #root .container {
    position: relative;
    overflow: hidden; }
    body #root .container.isMobile .fadeInDown,
    body #root .container.isMobile .fadeInUp,
    body #root .container.isMobile .fadeOutUp,
    body #root .container.isMobile .fadeOutDown {
      filter: none !important; }
    body #root .container.isMobile .ScrollbarsCustom-Track {
      display: none !important; }
    body #root .container .fallBackDiv {
      height: calc(100vh - 60px - 16px - 30px - 16px - 64px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-color: #141215; }
      body #root .container .fallBackDiv span {
        display: block; }
      body #root .container .fallBackDiv img {
        filter: grayscale(1);
        width: 400px; }
        @media (max-width: 768px) {
          body #root .container .fallBackDiv img {
            width: 300px; } }
      body #root .container .fallBackDiv h5 {
        text-align: center; }
      body #root .container .fallBackDiv h4 {
        text-align: center; }
    body #root .container .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
      height: 60px;
      margin: 0 auto 1rem auto; }
      @media (max-width: 768px) {
        body #root .container .header {
          padding: 0 1rem;
          margin-bottom: 0; } }
      body #root .container .header .logo {
        overflow: hidden;
        z-index: 2; }
        body #root .container .header .logo img {
          width: 46px;
          height: 50px;
          margin-right: 5px; }
          @media (max-width: 768px) {
            body #root .container .header .logo img {
              width: 40px;
              height: 44px; } }
      body #root .container .header ul {
        text-align: right; }
        @media (max-width: 768px) {
          body #root .container .header ul {
            display: none; } }
        body #root .container .header ul li {
          display: inline-block;
          list-style: none; }
          body #root .container .header ul li:not(:last-child) {
            margin-right: 1.5rem; }
          body #root .container .header ul li a {
            font-weight: 600;
            letter-spacing: 2px;
            font-family: Skyer;
            font-size: 18px;
            position: relative; }
            body #root .container .header ul li a.active:before {
              content: "";
              position: absolute;
              width: 110%;
              height: 1px;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              background: #bbb; }
      body #root .container .header .hamburgerMenu {
        max-width: 1rem;
        display: none;
        position: relative; }
        @media (max-width: 768px) {
          body #root .container .header .hamburgerMenu {
            display: block;
            margin-top: -10px; } }
        body #root .container .header .hamburgerMenu .line {
          width: 1rem;
          height: 2px;
          border-radius: 10px;
          background-color: #eee;
          position: absolute;
          right: 0;
          transition: 0.3s all ease-in-out; }
          body #root .container .header .hamburgerMenu .line:nth-child(1) {
            top: 0; }
          body #root .container .header .hamburgerMenu .line:nth-child(2) {
            top: 8px; }
        body #root .container .header .hamburgerMenu.crossIcon .line:nth-child(1) {
          top: 7.5px;
          transform: rotate(45deg); }
        body #root .container .header .hamburgerMenu.crossIcon .line:nth-child(2) {
          bottom: 7.5px;
          transform: rotate(-45deg); }
    body #root .container .welcomeMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%; }
      body #root .container .welcomeMessage h1 {
        font-size: 40px;
        text-align: center;
        letter-spacing: 6px; }
        @media (max-width: 768px) {
          body #root .container .welcomeMessage h1 {
            font-size: calc(2vw + 2vh + 4px); } }
      body #root .container .welcomeMessage h2 {
        text-align: center;
        font-size: 32px; }
        @media (max-width: 768px) {
          body #root .container .welcomeMessage h2 {
            font-size: calc(1.5vw + 1.5vh + 2px); } }
      body #root .container .welcomeMessage button {
        margin-top: 32px; }
    body #root .container .midContent {
      box-sizing: border-box;
      height: calc(100vh - 60px - 16px - 30px - 16px - 64px);
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      transition: 0.3s all ease-in-out; }
      @media (max-width: 768px) {
        body #root .container .midContent {
          height: calc(100vh - 60px - 16px - 30px - 16px); } }
      body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        top: 75px !important;
        width: 3px !important;
        opacity: 0.4 !important;
        transition: 0.1s all ease-in-out; }
        body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY:hover {
          width: 6px !important; }
        body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY > div {
          background-color: #eee !important; }
        body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY .ScrollbarsCustom-Thumb {
          cursor: none !important; }
      body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
        background-color: transparent !important; }
        body #root .container .midContent .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX > div {
          background-color: transparent !important; }
      body #root .container .midContent .title {
        margin: 2rem 0 1rem 0;
        position: relative; }
        body #root .container .midContent .title .headingTitle {
          font-size: 32px;
          letter-spacing: 4px;
          margin-left: 3rem;
          text-transform: capitalize; }
          @media (max-width: 768px) {
            body #root .container .midContent .title .headingTitle {
              font-size: 26px;
              margin-left: 1.5rem; } }
        body #root .container .midContent .title .circle {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: #eee;
          position: absolute;
          top: 26px;
          left: 8px;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
          body #root .container .midContent .title .circle:before, body #root .container .midContent .title .circle:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 0.5px solid #eee; }
          body #root .container .midContent .title .circle:before {
            -webkit-animation: ripple 2s linear infinite;
            animation: ripple 2s linear infinite; }
          @media (max-width: 768px) {
            body #root .container .midContent .title .circle {
              display: none; } }
        body #root .container .midContent .title .line {
          width: 1rem;
          height: 1px;
          background: #eee;
          position: absolute;
          top: 26px;
          left: 20px; }
          @media (max-width: 768px) {
            body #root .container .midContent .title .line {
              top: 20px;
              left: 0px;
              width: 1rem; } }
      body #root .container .midContent .gridDisplay {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); }
        @media (max-width: 768px) {
          body #root .container .midContent .gridDisplay {
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr)); } }
        body #root .container .midContent .gridDisplay.grid4 {
          grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr)); }
          @media (max-width: 768px) {
            body #root .container .midContent .gridDisplay.grid4 {
              grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)); } }
        body #root .container .midContent .gridDisplay .gridTitle {
          text-align: center;
          font-size: 26px;
          margin: 2rem 0;
          letter-spacing: 2px;
          transition: 0.3s all ease-in-out; }
          @media (max-width: 768px) {
            body #root .container .midContent .gridDisplay .gridTitle {
              font-size: 22px;
              margin: 1.5rem 0; } }
          body #root .container .midContent .gridDisplay .gridTitle.m-1 {
            margin: 1rem 0; }
          body #root .container .midContent .gridDisplay .gridTitle.small p {
            font-size: 16px;
            margin: 0.5rem 0; }
          body #root .container .midContent .gridDisplay .gridTitle.small img {
            width: 26px;
            height: 26px; }
          body #root .container .midContent .gridDisplay .gridTitle.small i {
            font-size: 32px; }
            @media (max-width: 768px) {
              body #root .container .midContent .gridDisplay .gridTitle.small i {
                font-size: 26px; } }
      body #root .container .midContent .description {
        color: #eee;
        position: relative;
        margin: 0.5rem 0;
        font-size: 17px;
        letter-spacing: 1.5px;
        line-height: 2rem;
        box-sizing: border-box; }
        body #root .container .midContent .description b {
          font-size: 18px; }
        @media (max-width: 768px) {
          body #root .container .midContent .description {
            font-size: 16px; } }
      body #root .container .midContent .subHeading {
        margin: 2rem 0;
        display: flex; }
        @media (max-width: 393px) {
          body #root .container .midContent .subHeading.mAutoMobile {
            justify-content: center; } }
        body #root .container .midContent .subHeading .lineIcon {
          margin-top: 0.6rem; }
          body #root .container .midContent .subHeading .lineIcon .circle {
            background: #eee;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-top: 3px; }
            @media (max-width: 768px) {
              body #root .container .midContent .subHeading .lineIcon .circle {
                margin-top: 0; } }
        body #root .container .midContent .subHeading .headContent {
          margin-left: 1rem; }
          @media (max-width: 768px) {
            body #root .container .midContent .subHeading .headContent {
              margin-left: 10px; } }
          body #root .container .midContent .subHeading .headContent a,
          body #root .container .midContent .subHeading .headContent h4 {
            font-size: 25px;
            letter-spacing: 3px;
            font-weight: 500;
            text-transform: capitalize; }
            @media (max-width: 768px) {
              body #root .container .midContent .subHeading .headContent a,
              body #root .container .midContent .subHeading .headContent h4 {
                font-size: 18px; } }
          body #root .container .midContent .subHeading .headContent p {
            color: #eee;
            font-size: 17px;
            line-height: 2rem;
            box-sizing: border-box;
            margin: 0.5rem 0; }
            @media (max-width: 768px) {
              body #root .container .midContent .subHeading .headContent p {
                font-size: 15px; } }
          body #root .container .midContent .subHeading .headContent h3 {
            font-size: 20px;
            letter-spacing: 3px;
            font-family: monospace;
            text-transform: capitalize; }
            @media (max-width: 768px) {
              body #root .container .midContent .subHeading .headContent h3 {
                font-size: 17px; } }
        body #root .container .midContent .subHeading.flexedContent {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          body #root .container .midContent .subHeading.flexedContent a {
            font-size: 26px;
            font-weight: 600;
            text-transform: capitalize; }
            @media (max-width: 768px) {
              body #root .container .midContent .subHeading.flexedContent a {
                font-size: 20px; }
                body #root .container .midContent .subHeading.flexedContent a > .external-icon {
                  display: none; } }
          body #root .container .midContent .subHeading.flexedContent .back-icon {
            margin-left: -4px; }
      body #root .container .midContent .mainContent {
        padding: 0 1.5rem 0 2rem; }
        @media (max-width: 768px) {
          body #root .container .midContent .mainContent {
            padding: 0 0.5rem; } }
    body #root .container .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      opacity: 0;
      margin-top: 1rem;
      height: 30px; }
      @media (max-width: 768px) {
        body #root .container .footer {
          justify-content: space-evenly; } }
      body #root .container .footer ul {
        padding: 0; }
        body #root .container .footer ul li {
          display: inline-block;
          list-style: none; }
          body #root .container .footer ul li:not(:last-child) {
            margin-right: 4rem; }
            @media (max-width: 768px) {
              body #root .container .footer ul li:not(:last-child) {
                margin-right: 4rem; } }
          body #root .container .footer ul li a {
            font-size: 14px;
            letter-spacing: 2px; }
    body #root .container .mobileMenu {
      background-color: #141215;
      position: absolute;
      top: 60px;
      right: 0;
      opacity: 0;
      z-index: -1;
      width: 100%;
      height: calc(100vh - 60px - 16px - 30px - 16px - 16px);
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column; }
      body #root .container .mobileMenu.show {
        transition: opacity 0.3s all ease-in-out; }
      body #root .container .mobileMenu a {
        font-size: 24px;
        text-align: center;
        margin: 3rem 0;
        position: relative; }
        body #root .container .mobileMenu a.active:before {
          content: "";
          position: absolute;
          width: 100px;
          height: 1px;
          top: 55%;
          left: 50%;
          transform: translateX(-50%);
          background: #bbb; }
