.lodash-icon {
  content: url(../images/lodash.svg);
  width: 34px;
  height: 32px;
}
.jest-icon {
  content: url(../images/jest.svg);
  width: 34px;
  height: 40px;
}
.ionic-icon {
  content: url(../images/ionic.svg);
  width: 34px;
  height: 32px;
}
.nightwatch-icon {
  content: url(../images/nightwatch.svg);
  width: 34px;
  height: 42px;
}
.next-icon {
  content: url(../images/next.svg);
  width: 50px;
  height: 32px;
}
.nuxt-icon {
  content: url(../images/nuxt.svg);
  width: 34px;
  height: 32px;
}
.facebook-icon {
  content: url(../images/facebook.svg);
  width: 16px;
  height: 16px;
}
.github-icon {
  content: url(../images/github.svg);
  width: 16px;
  height: 16px;
}
.linkedin-icon {
  content: url(../images/linkedin.svg);
  width: 16px;
  height: 16px;
}
.email-icon {
  content: url(../images/mail2.svg);
  width: 14px;
  height: 14px;
}
.loader-icon {
  content: url(../images/ripple.svg);
  width: 75px;
  height: 75px;
}
.back-icon {
  content: url(../images/back.svg);
  width: 22px;
  height: 20px;
}
.external-icon {
  content: url(../images/external.svg);
  width: 13px;
  height: 13px;
}
.line-icon {
  content: url(../images/line.svg);
}
.medium-icon {
  content: url(../images/medium.png);
  width: 14px;
  height: 14px;
}
.puppeteer-icon {
  content: url(../images/puppeteer-icon.png);
  width: 32px;
  height: 43px;
}
.websocket-icon {
  content: url(../images/web-sockets.svg);
  width: 34px;
  height: 30px;
}
