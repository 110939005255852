///// Variables /////
$verticalContainerMargin: 4vh;
$horizontalContainerMargin: 3vw;
$navHeight: 60px;
$navBottomMargin: 16px;
$footerHeight: 30px;
$footerTopMargin: 16px;
$containerMarginTopAndBottom: 64px;
$containerHeight: calc(100vh - 60px - 16px - 30px - 16px - 64px);
$mobileContainerHeight: calc(100vh - 60px - 16px - 30px - 16px);
$mobileMenuHeight: calc(100vh - 60px - 16px - 30px - 16px - 16px);
$dark1 : #141215;
$dark2: #1f2029;
$dark3: #07080A;
$grey: #bbb;
$grey1: #444;
$white: #eee;
$white1: #fff;
$offWhite: #cdcdcd;

$xxl: 1600px;
$xl: 1440px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 375px;