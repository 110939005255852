@import "./variables.scss";
@mixin animations ($animationName, $time) {
  -webkit-animation: $animationName $time linear forwards;
  animation: $animationName $time linear forwards;
}
button {
  padding: 10px 25px;
  border: 1px solid $white;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  font-family: monospace;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 2px;
}
.btnWhite {
  background-color: $white;
  color: $dark1;
  a {
    color: $dark1;
    transition: all 0.5s !important;
  }
}
.btnTransparent {
  background: transparent;
  pointer-events: none;
  color: $white;
}
.btnDark {
  background-color: $dark1;
  color: $white;
}
.animation0 {
  animation-fill-mode: backwards !important;
}
.skewedBtn {
  border-radius: 2px;
  transform: skew(-21deg);
  opacity: 0;
  transition: .3s all ease-in-out;
  >a,span {
    transform: skew(21deg);
    display: inline-block;
  }
}
.btnFillLeft {
  cursor: none;
  overflow: hidden;
  .fill {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    transition: all 0.3s !important;
  }
  &:hover {
    .fill {
      transform: translateX(0);
    }
    a,span {
      color: $dark1 !important;
    }
  }
}
.textCapitalize {
  text-transform: capitalize;
}
.opacity0 {
  opacity: 0 !important;
  pointer-events: none;
}
.opacity1 {
  opacity: 1 !important;
  pointer-events: unset;
}
.z1 {
  z-index: 1 !important;
}
.z0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: -1 !important;
}
.dNone {
  display: none !important;
}
.dBlock {
  display: block !important;
}
.dFlex {
  display: flex !important;
}
.bold {
  font-weight: 600 !important;
  color: $white1 !important;
}
.hC {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.vC {
  display: flex;
  align-items:center;
  flex-direction: column;
}
.vHC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mX1 {
  margin:0 1rem;
}
.mX2 {
  margin:0 2rem;
}
.mX3 {
  margin:0 3rem;
}
.mY1 {
  margin: 1rem 0;
}
.mY2 {
  margin: 2rem 0;
}
.mY3 {
  margin: 3rem 0;
}
.mL1 {
  margin-left: 1rem !important;
}
.mL2 {
  margin-left: 2rem !important;
}
.mL3 {
  margin-left: 3rem !important;
}
.mR1 {
  margin-right: 1rem;
}
.mR2 {
  margin-right: 2rem;
}
.mR3 {
  margin-right: 3rem;
} 
.mT1 {
  margin-top: 1rem !important;
}
.mT2 {
  margin-top: 2rem !important;
}
.mT3 {
  margin-top: 3rem !important;
}
.mB1 {
  margin-bottom: 1rem !important;
}
.mB2 {
  margin-bottom: 2rem !important;
}
.mB3 {
  margin-bottom: 3rem !important;
}
.t100 {
  transform: translateY(-100%);
}
.t0 {
  transform: translateY(0);
}
.monospace {
  font-family: monospace !important;
}
.skyer {
  font-family: Skyer !important;
}
.slideContentRight {
  @include animations(slideRight, .5s );
}
.fadeIn {
  will-change: transform, opacity,filter;
  @include animations(fadeIn, .3s);
}
.fadeOut {
  will-change: transform, opacity,filter;
  @include animations(fadeOut, .3s);
}
.fadeInDown {
  will-change: transform, opacity,filter;
  filter: blur(0);
  @include animations(fadeInDown, .3s);
}
.fadeInUp {
  will-change: transform, opacity,filter;
  filter: blur(0);
  @include animations(fadeInUp, .3s);
}
.fadeOutDown {
  will-change: transform, opacity,filter;
  filter: blur(1px);
  @include animations(fadeOutDown, .3s);
}
.fadeOutUp {
  will-change: transform, opacity,filter;
  filter: blur(1px);
  @include animations(fadeOutUp, .3s);
}

/********* Animations ***********/
@keyframes loadingBar {
  @for $i from 0 through 10 {
    $percentage: $i * 10%;
    #{$percentage} {
      width: $percentage;
    } 
  }
}
@keyframes slideRight {
  @for $i from 0 through 4 {
    $percentage: $i * 25%; 
    #{$percentage} {
      transform: translateX($percentage);
    } 
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
}
@keyframes ripple{
  0%{
      transform:scale(1)
  }
  75%{
      transform:scale(1.75);
      opacity:1
  }
  100%{
      transform:scale(2);
      opacity:0
  }
}
@keyframes scaleToggle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
/////////////////////////////////