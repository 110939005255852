@font-face {
  font-family: "devicon";
  src: url(../fonts/devicon.eot);
  src: url(../fonts/devicon.eot) format("embedded-opentype"),
    url(../fonts/devicon.woff) format("woff"),
    url(../fonts/devicon.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
[class^="devicon-"],
[class*=" devicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "devicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.devicon-nixos-plain-wordmark:before {
  content: "\e992";
}
.devicon-nixos-plain:before {
  content: "\e9b2";
}
.devicon-perl-plain:before {
  content: "\e9b3";
}
.devicon-tensorflow-line-wordmark:before {
  content: "\e9b4";
}
.devicon-tensorflow-line:before {
  content: "\e9b5";
}
.devicon-tensorflow-original-wordmark:before {
  content: "\e9b6";
}
.devicon-tensorflow-original:before {
  content: "\e9b7";
}
.devicon-zig-plain-wordmark:before {
  content: "\e9b8";
}
.devicon-zig-original:before {
  content: "\e9b9";
}
.devicon-raspberrypi-line-wordmark:before {
  content: "\e9ba";
}
.devicon-raspberrypi-line:before {
  content: "\e9bb";
}
.devicon-embeddedc-plain-wordmark:before {
  content: "\e9bc";
}
.devicon-embeddedc-plain:before {
  content: "\e9bd";
}
.devicon-labview-plain-wordmark:before {
  content: "\e9be";
}
.devicon-labview-plain:before {
  content: "\e9bf";
}
.devicon-modx-plain-wordmark:before {
  content: "\e9c0";
}
.devicon-modx-plain:before {
  content: "\e9c1";
}
.devicon-knockout-plain-wordmark:before {
  content: "\e9c2";
}
.devicon-rstudio-plain:before {
  content: "\e9c3";
}
.devicon-phalcon-plain:before {
  content: "\e9c4";
}
.devicon-minitab-plain:before {
  content: "\e9c5";
}
.devicon-bulma-plain:before {
  content: "\e9c6";
}
.devicon-spss-plain:before {
  content: "\e9c7";
}
.devicon-matlab-line:before {
  content: "\e9c8";
}
.devicon-matlab-plain:before {
  content: "\e9c9";
}
.devicon-karma-plain:before {
  content: "\e9ca";
}
.devicon-jest-plain:before {
  content: "\e9cb";
}
.devicon-circleci-plain-wordmark:before {
  content: "\e9cc";
}
.devicon-circleci-plain:before {
  content: "\e9cd";
}
.devicon-codecov-plain:before {
  content: "\e9ce";
}
.devicon-magento-line:before {
  content: "\e9cf";
}
.devicon-magento-original-wordmark:before {
  content: "\e9d0";
}
.devicon-magento-original:before {
  content: "\e9d1";
}
.devicon-shopware-original-wordmark:before {
  content: "\e9d2";
}
.devicon-shopware-original:before {
  content: "\e9d3";
}
.devicon-salesforce-plain:before {
  content: "\e9d4";
}
.devicon-vuestorefront-plain:before {
  content: "\e9d5";
}
.devicon-unix-original:before {
  content: "\e9d6";
}
.devicon-godot-plain-wordmark:before {
  content: "\e9d7";
}
.devicon-godot-plain:before {
  content: "\e9d8";
}
.devicon-vscode-plain-wordmark:before {
  content: "\e9d9";
}
.devicon-vscode-plain:before {
  content: "\e9da";
}
.devicon-julia-plain-wordmark:before {
  content: "\e9db";
}
.devicon-julia-plain:before {
  content: "\e9dc";
}
.devicon-crystal-original-wordmark:before {
  content: "\e9dd";
}
.devicon-crystal-original:before {
  content: "\e9de";
}
.devicon-tailwindcss-plain:before {
  content: "\e9df";
}
.devicon-tailwindcss-original-wordmark:before {
  content: "\e9e0";
}
.devicon-weblate-plain-wordmark:before {
  content: "\e999";
}
.devicon-weblate-plain:before {
  content: "\e99a";
}
.devicon-thealgorithms-plain-wordmark:before {
  content: "\e99b";
}
.devicon-thealgorithms-plain:before {
  content: "\e99c";
}
.devicon-spring-plain-wordmark:before {
  content: "\e99d";
}
.devicon-spring-plain:before {
  content: "\e99e";
}
.devicon-rails-plain-wordmark:before {
  content: "\e99f";
}
.devicon-rails-plain:before {
  content: "\e9a0";
}
.devicon-phoenix-plain-wordmark:before {
  content: "\e9a1";
}
.devicon-phoenix-plain:before {
  content: "\e9a2";
}
.devicon-nextjs-original-wordmark:before {
  content: "\e9a3";
}
.devicon-nextjs-line:before {
  content: "\e9a4";
}
.devicon-nextjs-original:before {
  content: "\e9a5";
}
.devicon-lua-plain-wordmark:before {
  content: "\e9a6";
}
.devicon-lua-plain:before {
  content: "\e9a7";
}
.devicon-graphql-plain-wordmark:before {
  content: "\e9a8";
}
.devicon-graphql-plain:before {
  content: "\e9a9";
}
.devicon-gitter-plain-wordmark:before {
  content: "\e9aa";
}
.devicon-gitter-plain:before {
  content: "\e9ab";
}
.devicon-figma-plain:before {
  content: "\e9ac";
}
.devicon-digitalocean-plain-wordmark:before {
  content: "\e9ad";
}
.devicon-digitalocean-plain:before {
  content: "\e9ae";
}
.devicon-dotnetcore-plain:before {
  content: "\e9af";
}
.devicon-dart-plain-wordmark:before {
  content: "\e9b0";
}
.devicon-dart-plain:before {
  content: "\e9b1";
}
.devicon-r-plain:before {
  content: "\e98e";
}
.devicon-r-original:before {
  content: "\e98f";
}
.devicon-ocaml-plain-wordmark:before {
  content: "\e990";
}
.devicon-ocaml-plain:before {
  content: "\e991";
}
.devicon-jupyter-plain-wordmark:before {
  content: "\e993";
}
.devicon-jupyter-plain:before {
  content: "\e994";
}
.devicon-fsharp-plain:before {
  content: "\e995";
}
.devicon-elixir-plain-wordmark:before {
  content: "\e996";
}
.devicon-elixir-plain:before {
  content: "\e997";
}
.devicon-aarch64-plain:before {
  content: "\e998";
}
.devicon-xd-line:before {
  content: "\e97a";
}
.devicon-xd-plain:before {
  content: "\e97b";
}
.devicon-uwsgi-plain:before {
  content: "\e97c";
}
.devicon-microsoftsqlserver-plain-wordmark:before {
  content: "\e97d";
}
.devicon-microsoftsqlserver-plain:before {
  content: "\e97e";
}
.devicon-sqlalchemy-original-wordmark:before {
  content: "\e97f";
}
.devicon-sqlalchemy-plain:before {
  content: "\e980";
}
.devicon-rocksdb-plain:before {
  content: "\e981";
}
.devicon-objectivec-plain:before {
  content: "\e982";
}
.devicon-kubernetes-plain-wordmark:before {
  content: "\e983";
}
.devicon-kubernetes-plain:before {
  content: "\e984";
}
.devicon-googlecloud-plain-wordmark:before {
  content: "\e985";
}
.devicon-googlecloud-plain:before {
  content: "\e986";
}
.devicon-flask-original-wordmark:before {
  content: "\e987";
}
.devicon-flask-original:before {
  content: "\e988";
}
.devicon-firebase-plain-wordmark:before {
  content: "\e989";
}
.devicon-firebase-plain:before {
  content: "\e98a";
}
.devicon-eleventy-plain:before {
  content: "\e98b";
}
.devicon-appwrite-plain-wordmark:before {
  content: "\e98c";
}
.devicon-appwrite-plain:before {
  content: "\e98d";
}
.devicon-bash-plain:before {
  content: "\e979";
}
.devicon-woocommerce-plain-wordmark:before {
  content: "\e966";
}
.devicon-woocommerce-plain:before {
  content: "\e967";
}
.devicon-typo3-plain-wordmark:before {
  content: "\e968";
}
.devicon-typo3-plain:before {
  content: "\e969";
}
.devicon-premierepro-plain:before {
  content: "\e96a";
}
.devicon-nestjs-plain-wordmark:before {
  content: "\e96b";
}
.devicon-nestjs-plain:before {
  content: "\e96c";
}
.devicon-materialui-plain:before {
  content: "\e96d";
}
.devicon-kotlin-plain-wordmark:before {
  content: "\e96e";
}
.devicon-kotlin-plain:before {
  content: "\e96f";
}
.devicon-jenkins-plain:before {
  content: "\e970";
}
.devicon-jenkins-line:before {
  content: "\e971";
}
.devicon-haxe-plain:before {
  content: "\e972";
}
.devicon-gatsby-plain-wordmark:before {
  content: "\e973";
}
.devicon-gatsby-plain:before {
  content: "\e974";
}
.devicon-flutter-plain:before {
  content: "\e975";
}
.devicon-composer-line-wordmark:before {
  content: "\e976";
}
.devicon-composer-line:before {
  content: "\e977";
}
.devicon-aftereffects-plain:before {
  content: "\e978";
}
.devicon-yunohost-plain:before {
  content: "\e963";
}
.devicon-redux-original:before {
  content: "\e964";
}
.devicon-clojurescript-plain:before {
  content: "\e965";
}
.devicon-clojure-line:before {
  content: "\e962";
}
.devicon-mocha-plain:before {
  content: "\e961";
}
.devicon-haskell-plain:before {
  content: "\e95f";
}
.devicon-haskell-plain-wordmark:before {
  content: "\e960";
}
.devicon-codepen-original-wordmark:before {
  content: "\e95d";
}
.devicon-codepen-plain:before {
  content: "\e95e";
}
.devicon-groovy-plain:before {
  content: "\e958";
}
.devicon-rust-plain:before {
  content: "\e959";
}
.devicon-scala-plain-wordmark:before {
  content: "\e95a";
}
.devicon-scala-plain:before {
  content: "\e95b";
}
.devicon-grails-plain:before {
  content: "\e95c";
}
.devicon-sketch-line-wordmark:before {
  content: "\e94c";
}
.devicon-sketch-line:before {
  content: "\e94d";
}
.devicon-npm-original-wordmark:before {
  content: "\e952";
}
.devicon-ionic-original-wordmark:before {
  content: "\e953";
}
.devicon-ionic-original:before {
  content: "\e954";
}
.devicon-ember-original-wordmark:before {
  content: "\e955";
}
.devicon-electron-original-wordmark:before {
  content: "\e956";
}
.devicon-electron-original:before {
  content: "\e957";
}
.devicon-vagrant-plain-wordmark:before {
  content: "\e94e";
}
.devicon-vagrant-plain:before {
  content: "\e94f";
}
.devicon-yarn-plain-wordmark:before {
  content: "\e950";
}
.devicon-yarn-plain:before {
  content: "\e951";
}
.devicon-handlebars-plain-wordmark:before {
  content: "\e94a";
}
.devicon-handlebars-plain:before {
  content: "\e94b";
}
.devicon-couchdb-plain-wordmark:before {
  content: "\e948";
}
.devicon-couchdb-plain:before {
  content: "\e949";
}
.devicon-behance-plain-wordmark:before {
  content: "\e943";
}
.devicon-behance-plain:before {
  content: "\e945";
}
.devicon-linkedin-plain-wordmark:before {
  content: "\e946";
}
.devicon-linkedin-plain:before {
  content: "\e947";
}
.devicon-ceylon-plain:before {
  content: "\e944";
}
.devicon-elm-plain-wordmark:before {
  content: "\e941";
}
.devicon-elm-plain:before {
  content: "\e942";
}
.devicon-cakephp-plain-wordmark:before {
  content: "\e93f";
}
.devicon-cakephp-plain:before {
  content: "\e940";
}
.devicon-stylus-original:before {
  content: "\e93e";
}
.devicon-express-original-wordmark:before {
  content: "\e93c";
}
.devicon-express-original:before {
  content: "\e93d";
}
.devicon-devicon-plain-wordmark:before {
  content: "\e93a";
}
.devicon-devicon-plain:before {
  content: "\e93b";
}
.devicon-intellij-plain-wordmark:before {
  content: "\e932";
}
.devicon-intellij-plain:before {
  content: "\e933";
}
.devicon-pycharm-plain-wordmark:before {
  content: "\e934";
}
.devicon-pycharm-plain:before {
  content: "\e935";
}
.devicon-rubymine-plain-wordmark:before {
  content: "\e936";
}
.devicon-rubymine-plain:before {
  content: "\e937";
}
.devicon-webstorm-plain-wordmark:before {
  content: "\e938";
}
.devicon-webstorm-plain:before {
  content: "\e939";
}
.devicon-tomcat-line-wordmark:before {
  content: "\e92c";
}
.devicon-tomcat-line:before {
  content: "\e92d";
}
.devicon-vuejs-line-wordmark:before {
  content: "\e92e";
}
.devicon-vuejs-line:before {
  content: "\e92f";
}
.devicon-vuejs-plain-wordmark:before {
  content: "\e930";
}
.devicon-vuejs-plain:before {
  content: "\e931";
}
.devicon-swift-plain-wordmark:before {
  content: "\e92a";
}
.devicon-swift-plain:before {
  content: "\e92b";
}
.devicon-webpack-plain-wordmark:before {
  content: "\e928";
}
.devicon-webpack-plain:before {
  content: "\e929";
}
.devicon-visualstudio-plain-wordmark:before {
  content: "\e926";
}
.devicon-visualstudio-plain:before {
  content: "\e927";
}
.devicon-slack-plain-wordmark:before {
  content: "\e924";
}
.devicon-slack-plain:before {
  content: "\e925";
}
.devicon-sequelize-plain-wordmark:before {
  content: "\e922";
}
.devicon-sequelize-plain:before {
  content: "\e923";
}
.devicon-typescript-plain:before {
  content: "\e920";
}
.devicon-babel-plain:before {
  content: "\e921";
}
.devicon-facebook-plain:before {
  content: "\e91c";
}
.devicon-google-plain-wordmark:before {
  content: "\e91d";
}
.devicon-google-plain:before {
  content: "\e91e";
}
.devicon-twitter-original:before {
  content: "\e91f";
}
.devicon-mocha:before {
  content: "\e919";
}
.devicon-jasmine-plain:before {
  content: "\e91a";
}
.devicon-jasmine-wordmark:before {
  content: "\e91b";
}
.devicon-gatling-plain:before {
  content: "\e917";
}
.devicon-gatling-plain-wordmark:before {
  content: "\e918";
}
.devicon-phpstorm-plain:before {
  content: "\e911";
}
.devicon-phpstorm-plain-wordmark:before {
  content: "\e912";
}
.devicon-sourcetree-original:before {
  content: "\e913";
}
.devicon-sourcetree-original-wordmark:before {
  content: "\e914";
}
.devicon-ssh-original:before {
  content: "\e915";
}
.devicon-ssh-original-wordmark:before {
  content: "\e916";
}
.devicon-jeet-plain:before {
  content: "\e903";
}
.devicon-jeet-plain-wordmark:before {
  content: "\e906";
}
.devicon-gitlab-plain:before {
  content: "\e907";
}
.devicon-gitlab-plain-wordmark:before {
  content: "\e908";
}
.devicon-github-original:before {
  content: "\e909";
}
.devicon-github-original-wordmark:before {
  content: "\e90a";
}
.devicon-d3js-plain:before {
  content: "\e90b";
}
.devicon-d3js-original:before {
  content: "\e90c";
}
.devicon-confluence-original:before {
  content: "\e90d";
}
.devicon-confluence-original-wordmark:before {
  content: "\e90e";
}
.devicon-bitbucket-original:before {
  content: "\e90f";
}
.devicon-bitbucket-original-wordmark:before {
  content: "\e910";
}
.devicon-gradle-plain:before {
  content: "\e902";
}
.devicon-gradle-plain-wordmark:before {
  content: "\e8f1";
}
.devicon-cucumber-plain:before {
  content: "\e904";
}
.devicon-cucumber-plain-wordmark:before {
  content: "\e905";
}
.devicon-protractor-plain:before {
  content: "\e900";
}
.devicon-protractor-plain-wordmark:before {
  content: "\e901";
}
.devicon-safari-line-wordmark:before {
  content: "\e632";
}
.devicon-safari-line:before {
  content: "\e63a";
}
.devicon-safari-plain-wordmark:before {
  content: "\e63b";
}
.devicon-safari-plain:before {
  content: "\e63c";
}
.devicon-jetbrains-plain:before {
  content: "\e63d";
}
.devicon-django-line:before {
  content: "\e63e";
}
.devicon-django-plain:before {
  content: "\e63f";
}
.devicon-gimp-plain:before {
  content: "\e633";
}
.devicon-redhat-plain-wordmark:before {
  content: "\e62a";
}
.devicon-redhat-plain:before {
  content: "\e62b";
}
.devicon-cplusplus-line:before {
  content: "\e634";
}
.devicon-cplusplus-plain:before {
  content: "\e635";
}
.devicon-csharp-line:before {
  content: "\e636";
}
.devicon-csharp-plain:before {
  content: "\e637";
}
.devicon-c-line:before {
  content: "\e638";
}
.devicon-c-plain:before {
  content: "\e639";
}
.devicon-nodewebkit-line-wordmark:before {
  content: "\e611";
}
.devicon-nodewebkit-line:before {
  content: "\e612";
}
.devicon-nodewebkit-plain-wordmark:before {
  content: "\e613";
}
.devicon-nodewebkit-plain:before {
  content: "\e614";
}
.devicon-nginx-original:before {
  content: "\e615";
}
.devicon-erlang-plain-wordmark:before {
  content: "\e616";
}
.devicon-erlang-plain:before {
  content: "\e617";
}
.devicon-doctrine-line-wordmark:before {
  content: "\e618";
}
.devicon-doctrine-line:before {
  content: "\e619";
}
.devicon-doctrine-plain-wordmark:before {
  content: "\e61a";
}
.devicon-doctrine-plain:before {
  content: "\e625";
}
.devicon-apache-line-wordmark:before {
  content: "\e626";
}
.devicon-apache-line:before {
  content: "\e627";
}
.devicon-apache-plain-wordmark:before {
  content: "\e628";
}
.devicon-apache-plain:before {
  content: "\e629";
}
.devicon-go-line:before {
  content: "\e610";
}
.devicon-redis-plain-wordmark:before {
  content: "\e606";
}
.devicon-redis-plain:before {
  content: "\e607";
}
.devicon-meteor-plain-wordmark:before {
  content: "\e608";
}
.devicon-meteor-plain:before {
  content: "\e609";
}
.devicon-heroku-line-wordmark:before {
  content: "\e60a";
}
.devicon-heroku-line:before {
  content: "\e60b";
}
.devicon-heroku-plain-wordmark:before {
  content: "\e60c";
}
.devicon-heroku-plain:before {
  content: "\e60f";
}
.devicon-go-plain:before {
  content: "\e61b";
}
.devicon-docker-plain-wordmark:before {
  content: "\e61e";
}
.devicon-docker-plain:before {
  content: "\e61f";
}
.devicon-symfony-original-wordmark:before {
  content: "\e602";
}
.devicon-symfony-original:before {
  content: "\e605";
}
.devicon-react-original-wordmark:before {
  content: "\e600";
}
.devicon-react-original:before {
  content: "\e601";
}
.devicon-amazonwebservices-original:before {
  content: "\e603";
}
.devicon-amazonwebservices-plain-wordmark:before {
  content: "\e604";
}
.devicon-android-plain-wordmark:before {
  content: "\e60d";
}
.devicon-android-plain:before {
  content: "\e60e";
}
.devicon-angularjs-plain-wordmark:before {
  content: "\e61c";
}
.devicon-angularjs-plain:before {
  content: "\e61d";
}
.devicon-appcelerator-original:before {
  content: "\e620";
}
.devicon-appcelerator-plain-wordmark:before {
  content: "\e621";
}
.devicon-apple-original:before {
  content: "\e622";
}
.devicon-atom-original-wordmark:before {
  content: "\e623";
}
.devicon-atom-original:before {
  content: "\e624";
}
.devicon-backbonejs-plain-wordmark:before {
  content: "\e62c";
}
.devicon-backbonejs-plain:before {
  content: "\e62d";
}
.devicon-bootstrap-plain-wordmark:before {
  content: "\e62e";
}
.devicon-bootstrap-plain:before {
  content: "\e62f";
}
.devicon-bower-line-wordmark:before {
  content: "\e630";
}
.devicon-bower-line:before {
  content: "\e631";
}
.devicon-bower-plain-wordmark:before {
  content: "\e64e";
}
.devicon-bower-plain:before {
  content: "\e64f";
}
.devicon-chrome-plain-wordmark:before {
  content: "\e665";
}
.devicon-chrome-plain:before {
  content: "\e666";
}
.devicon-codeigniter-plain-wordmark:before {
  content: "\e667";
}
.devicon-codeigniter-plain:before {
  content: "\e668";
}
.devicon-coffeescript-original-wordmark:before {
  content: "\e669";
}
.devicon-coffeescript-original:before {
  content: "\e66a";
}
.devicon-css3-plain-wordmark:before {
  content: "\e678";
}
.devicon-css3-plain:before {
  content: "\e679";
}
.devicon-debian-plain-wordmark:before {
  content: "\e67e";
}
.devicon-debian-plain:before {
  content: "\e67f";
}
.devicon-dot-net-plain-wordmark:before {
  content: "\e6d3";
}
.devicon-dot-net-plain:before {
  content: "\e6d4";
}
.devicon-drupal-plain-wordmark:before {
  content: "\e6e2";
}
.devicon-drupal-plain:before {
  content: "\e6e3";
}
.devicon-firefox-plain-wordmark:before {
  content: "\e75d";
}
.devicon-firefox-plain:before {
  content: "\e75e";
}
.devicon-foundation-plain-wordmark:before {
  content: "\e7a2";
}
.devicon-foundation-plain:before {
  content: "\e7a3";
}
.devicon-git-plain-wordmark:before {
  content: "\e7a7";
}
.devicon-git-plain:before {
  content: "\e7a8";
}
.devicon-grunt-line-wordmark:before {
  content: "\e7a9";
}
.devicon-grunt-line:before {
  content: "\e7aa";
}
.devicon-grunt-plain-wordmark:before {
  content: "\e7ea";
}
.devicon-grunt-plain:before {
  content: "\e7eb";
}
.devicon-gulp-plain:before {
  content: "\e7ec";
}
.devicon-html5-plain-wordmark:before {
  content: "\e7f6";
}
.devicon-html5-plain:before {
  content: "\e7f7";
}
.devicon-ie10-original:before {
  content: "\e7f8";
}
.devicon-illustrator-line:before {
  content: "\e7f9";
}
.devicon-illustrator-plain:before {
  content: "\e7fa";
}
.devicon-inkscape-plain-wordmark:before {
  content: "\e834";
}
.devicon-inkscape-plain:before {
  content: "\e835";
}
.devicon-java-plain-wordmark:before {
  content: "\e841";
}
.devicon-java-plain:before {
  content: "\e842";
}
.devicon-javascript-plain:before {
  content: "\e845";
}
.devicon-jquery-plain-wordmark:before {
  content: "\e849";
}
.devicon-jquery-plain:before {
  content: "\e84a";
}
.devicon-krakenjs-plain-wordmark:before {
  content: "\e84f";
}
.devicon-krakenjs-plain:before {
  content: "\e850";
}
.devicon-laravel-plain-wordmark:before {
  content: "\e851";
}
.devicon-laravel-plain:before {
  content: "\e852";
}
.devicon-less-plain-wordmark:before {
  content: "\e853";
}
.devicon-linux-plain:before {
  content: "\eb1c";
}
.devicon-mongodb-plain-wordmark:before {
  content: "\eb43";
}
.devicon-mongodb-plain:before {
  content: "\eb44";
}
.devicon-moodle-plain-wordmark:before {
  content: "\eb5a";
}
.devicon-moodle-plain:before {
  content: "\eb5b";
}
.devicon-mysql-plain-wordmark:before {
  content: "\eb60";
}
.devicon-mysql-plain:before {
  content: "\eb61";
}
.devicon-nodejs-plain-wordmark:before {
  content: "\eb69";
}
.devicon-nodejs-plain:before {
  content: "\eb6a";
}
.devicon-oracle-original:before {
  content: "\eb6b";
}
.devicon-photoshop-line:before {
  content: "\eb6c";
}
.devicon-photoshop-plain:before {
  content: "\eb6d";
}
.devicon-php-plain:before {
  content: "\eb71";
}
.devicon-postgresql-plain-wordmark:before {
  content: "\eb7c";
}
.devicon-postgresql-plain:before {
  content: "\eb7d";
}
.devicon-python-plain-wordmark:before {
  content: "\eb88";
}
.devicon-python-plain:before {
  content: "\eb89";
}
.devicon-ruby-plain-wordmark:before {
  content: "\ebc9";
}
.devicon-ruby-plain:before {
  content: "\ebca";
}
.devicon-sass-original:before {
  content: "\ebcb";
}
.devicon-travis-plain-wordmark:before {
  content: "\ebcc";
}
.devicon-travis-plain:before {
  content: "\ebcd";
}
.devicon-trello-plain-wordmark:before {
  content: "\ebce";
}
.devicon-trello-plain:before {
  content: "\ebcf";
}
.devicon-ubuntu-plain-wordmark:before {
  content: "\ebd0";
}
.devicon-ubuntu-plain:before {
  content: "\ebd1";
}
.devicon-vim-plain:before {
  content: "\ebf3";
}
.devicon-windows8-original-wordmark:before {
  content: "\ebf4";
}
.devicon-windows8-original:before {
  content: "\ebf5";
}
.devicon-wordpress-plain-wordmark:before {
  content: "\ebfd";
}
.devicon-wordpress-plain:before {
  content: "\ebfe";
}
.devicon-yii-plain-wordmark:before {
  content: "\ec01";
}
.devicon-yii-plain:before {
  content: "\ec02";
}
.devicon-zend-plain-wordmark:before {
  content: "\ec03";
}
.devicon-zend-plain:before {
  content: "\ec04";
}
